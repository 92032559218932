import React from "react"
// import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
// import Image from "../components/image"

const BuildingSupplies = () => (
  <Layout>
    <SEO title="Building Supplies" />

    <div className="page-banner">
      <div className="container">
        <h1>
          Building Supplies
        </h1>
      </div>
    </div>

    <div className="banner-skew">
      <div className="skew-left"></div>
    </div>

    <div className="page-content">
      <div className="container">

        <h2>Building Supplies</h2>
        <div>
          <p>GradeA Building Supplies is the distribution partner of Brucha Panels in New Zealand, Australia and the Pacific Islands.</p>
          <p>Established in 1948 in Austria, Brucha is a market lead- er of insulated panel technology serving the industrial, commercial, architectural, cold storage, food service and retail industries.</p>
          <p>With 16 different types of insulation thickness, our insu- lated panels will meet all physical requirements of every project, whether its for wall, façade, or roof.</p>
          <p>GradeA Group also offers a one-stop-shop service for design, supply and installation of the following around New Zealand:</p>

          <ul>
            <li>Glass and aluminium works</li>
            <li>Commercial facade</li>
            <li>Insulated panels</li>
            <li>Waterproofing</li>
          </ul>
        </div>

      </div>
    </div>

  </Layout>
)

export default BuildingSupplies
